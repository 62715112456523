<template>
  <CModal class="ActionModel" size="xl" :show.sync="Toggle" :title="$t('Video/Detail.ChooseVideoContent')" :centered="true" @update:show="HandleHideModal()">
    <CCard>
      <CCardHeader>
        <CInput v-model="SearchFilter.Name" placeholder="請輸入關鍵字以搜尋內容..." @change="GetList()" class="mb-0" />
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          :loading="Loading"
          id="ProductList"
          :items="List"
          :fields="Field"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <div class="text-center">
              <label class="mb-0">
                <input v-if="!Single" type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </div>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input :type="(Single === true ? 'radio' : 'checkbox')" :id="'Checkbox-List-' + item._id" :value="item._id" v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                {{ $t('Post/List.StatusOptions.' + item.Status) }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination
          :activePage.sync="Pagination.Page"
          :pages.sync="Pagination.TotalPages"
          @update:activePage="Init()"
        />
      </CCardFooter>
    </CCard>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton id="ChooseProduct" @click="Choose()" color="success" block>
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Single', 'Toggle'],
  name: 'VideoListComponent',
  data() {
    return {
      List: [],
      SearchFilter: {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        Name: '',
      },
      ShowFilter: 'All',
      FilterModel: false,
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: this.$t('Post/List.Cover') },
        { key: 'Name', label: this.$t('Post/List.Name') },
        { key: 'Slug', label: this.$t('Post/List.Slug') },
        { key: 'Status', label: this.$t('Post/List.Status') }
      ]
    }
  },
  mounted() {
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order
      }
      if (this.SearchFilter.Name !== '') {
        RequestQuery.name = this.SearchFilter.Name
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/video/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories() {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return {label: category.Name, value: category.Slug}
        })
      }).catch((err) => {
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.SeriesNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    Choose() {
      this.$emit('ChooseVideoContent', this.List.filter(data => {
        return this.ChooseIndex.includes(data._id)
      }))
      this.HandleHideModal()
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    }
  }
}
</script>

